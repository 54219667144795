import { NextPageContext } from "next";
import Head from "next/head";

import { getSession } from "@lib/auth";

import { CallToAction } from "@components/landingpage/CallToAction";
import { Faqs } from "@components/landingpage/Faqs";
import { Footer } from "@components/landingpage/Footer";
import { Header } from "@components/landingpage/Header";
import { Hero } from "@components/landingpage/Hero";
import { Pricing } from "@components/landingpage/Pricing";
import { PrimaryFeatures } from "@components/landingpage/PrimaryFeatures";
import { SecondaryFeatures } from "@components/landingpage/SecondaryFeatures";
import { Testimonials } from "@components/landingpage/Testimonials";

function Home() {
  return (
    <>
      <Head>
        <title>MyCalendar - Scheduling made simple</title>
        <meta
          name="description"
          content="Efficiently schedule appointments and boost productivity with our user-friendly tool."
        />
      </Head>
      <Header />
      <main>
        <Hero />
        <PrimaryFeatures />
        <SecondaryFeatures />
        <CallToAction />
        <Testimonials />
        <Pricing />
        <Faqs />
      </main>
      <Footer />
    </>
  );
}

export async function getServerSideProps(context: NextPageContext) {
  const session = await getSession(context);
  if (session?.user?.id) {
    return { redirect: { permanent: false, destination: "/event-types" } };
  }
  return { props: {} };
}

export default Home;
